.home {
	padding: 4% 4%;
	min-height: 100%;
	width: 100%;
}

#grid-support {
	background-color: transparent;
	background-image:       linear-gradient(0deg, transparent 24%, rgba(0, 0, 0, 0.25) 25%, rgba(0, 0, 0, 0.25) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.25) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(0, 0, 0, 0.25) 25%, rgba(0, 0, 0, 0.25) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.25) 76%, transparent 77%, transparent);
  height:100%;
  background-size:100px 100px;
}
